
import { defineComponent } from "vue"
import Swal from "sweetalert2/dist/sweetalert2.js"
import MessageService from "@/core/services/MessageService"

export default defineComponent({
  name: "team-members",
  props: {
    route_params: {
      type: Object,
      required: true
    }
  },
  setup() {
    const year = 2023;
    const month = 6;
    const now = new Date()
    const reports = [] as {
      date: string,
      readableDate: string
    }[]

    for(
      let m = month, y = year; 
      y.toString() + m.toString() != 
      now.getFullYear().toString() + (now.getMonth()+1).toString(); 
      m++
    ) {

      if(m > 12) {
        m = 1
        y++
      }

      reports.unshift({
        date: y + '-' + prefixZero(m),
        readableDate: toReadableDate(y + '-' + prefixZero(m))
      })
    }

    function toReadableDate(date: string) {
      const d = new Date(date + "-28")
      const month = d.toLocaleString('default', { month: 'long' })
      const year = d.getFullYear()
      return `${month} ${year}`
    }

    function prefixZero(num: number) {
      return num < 10 ? '0' + num : num
    }

    async function sendReport(accountNum: number, yearMonth: string) {
      let resp
      try {
        resp = await MessageService.postMonthlyPerformance(accountNum, yearMonth)
      } catch (e) {
        console.error("Problem posting message resend request", e)
      }

      if(resp.status){
        Swal.fire({
          title: "Report queued for send!",
          text: "The email will be sent within the next five minutes"
        })
      } else {
        Swal.fire({
          text: "Error requesting report resend",
          icon: "error",
          confirmButtonText: "Ok",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary"
          }
        })
      }
    }

    return {
      toReadableDate,
      sendReport,
      reports
    }
  }
});
