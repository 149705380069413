import ApiService from "@/core/services/ApiService"
import { AxiosResponse } from "axios"

export default class MessageService {
  static async postMonthlyPerformance(
    accountNum,
    month: string
  ){
    try {
      return MessageService.post(`/accounts/${ accountNum }/monthly-performance/${ month }`, "")
    } catch (e) {
      console.error("Problem fetching account users", e)
    }

    return false
  }

  public static post(resource: string, params: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params)
  }
}
